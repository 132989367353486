<template>
    <b-container class="vh-100 wrapper">
        <b-row class="border border-dark rounded navWrapper">
            <AppLayoutNav />
        </b-row>
        <b-row class="border border-dark rounded rowStyle">
          <h2 class="w-100 text-center">{{ title }}</h2>
            <slot />
        </b-row>
    </b-container>
</template>

<script>
import AppLayoutNav from '@/layouts/AppLayoutNav'
export default {
    name: "AppLayoutGraphView",
    components: {
        AppLayoutNav
    },
    props: {
        title : {
         type: String,
        },
    }
}
</script>

<style scoped>
.wrapper {
    padding: 15px 25px 5px 25px;
    overflow: hidden;
}
.navWrapper {
    background-color: #8c8c8c;
    padding-bottom: 3px;
}
.rowStyle {
    height: 90%;
    background-color: #8c8c8c;
}
</style>